import React, { useState } from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Card, Input, Collapse } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import { PlusCircleFilled, MinusCircleFilled } from "@ant-design/icons"

import IconFAQGeneralType from "../../images/icono-cripto-general.svg"
import IconFAQAccountType from "../../images/icono-cripto-cuenta.svg"
import IconFAQAboutType from "../../images/icono-cripto-exchange.svg"
import IconFAQActiveType from "../../images/icono-cripto-credito-wallet.svg"

import "./faqs.scss"
import { darkGrayColor } from "../../theme/colors.scss"
import stringToHTML from "../../utils/stringToHTML.js"
import {
  lPhantPrivacyPolicyLink,
  lPhantPrivacyPolicyLinkDisplay,
} from "../../utils/variables.js"

const FAQs = ({ intl, location }) => {
  const onSearch = () => {
    console.log("searching..")
  }
  const onChange = () => {
    console.log("changing..")
  }
  const [actualCard, setActualCard] = useState(1)
  const [activeKey, setActiveKey] = useState(
    intl.formatMessage({
      id: "card-1-faq-1-title",
    })
  )
  return (
    <Layout prefooter="1" menuSelector={location?.state?.menuSelector || 1}>
      <SEO title="LoanCalculator" />
      <div className="faqs">
        <div className="faqs-header">
          <h1 className="faqs-title">
            {stringToHTML(intl.formatMessage({ id: "faqs-title" }))}
          </h1>
          <h1 className="faqs-message">
            <Input.Search
              placeholder={stringToHTML(
                intl.formatMessage({ id: "faqs-input-placeholder" })
              )}
              allowClear
              enterButton={<SearchOutlined />}
              size="large"
              onSearch={onSearch}
              onChange={onChange}
              color="#FF0000"
            />
          </h1>
        </div>

        <div className="content-wrapper row center">
          <div className="col spacer center">
            <Card
              key={1}
              className={
                actualCard === 1
                  ? "row card-selected center"
                  : "row card-not-selected center"
              }
              onClick={() => {
                setActualCard(1)
                setActiveKey(
                  intl.formatMessage({
                    id: "card-1-faq-1-title",
                  })
                )
              }}
              bordered
              hoverable
            >
              <IconFAQGeneralType />
              <div className="col ">
                <h3 style={{ fontSize: 18, fontFamily: "Cairo-Bold" }}>
                  {stringToHTML(
                    intl.formatMessage({ id: "faqs-card-1-title" })
                  )}
                </h3>
              </div>
            </Card>
          </div>
          <div className="col spacer center">
            <Card
              key={2}
              className={
                actualCard === 2
                  ? "row card-selected center"
                  : "row card-not-selected center"
              }
              onClick={() => {
                setActualCard(2)
                setActiveKey(
                  intl.formatMessage({
                    id: "card-2-faq-1-title",
                  })
                )
              }}
              bordered
              hoverable
            >
              <IconFAQAccountType />
              <div className="col">
                <h3 style={{ fontSize: 18, fontFamily: "Cairo-Bold" }}>
                  {stringToHTML(
                    intl.formatMessage({ id: "faqs-card-2-title" })
                  )}
                </h3>
              </div>
            </Card>
          </div>

          <div className="col spacer center">
            <Card
              key={3}
              className={
                actualCard === 3
                  ? "row card-selected center"
                  : "row card-not-selected center"
              }
              onClick={() => {
                setActualCard(3)
                setActiveKey(
                  intl.formatMessage({
                    id: "card-3-faq-1-title",
                  })
                )
              }}
              bordered
              hoverable
            >
              <IconFAQAboutType />
              <div className="col">
                <h3 style={{ fontSize: 18, fontFamily: "Cairo-Bold" }}>
                  {stringToHTML(
                    intl.formatMessage({ id: "faqs-card-3-title" })
                  )}
                </h3>
              </div>
            </Card>
          </div>
          <div className="col spacer center">
            <Card
              key={4}
              className={
                actualCard === 4
                  ? "row card-selected center"
                  : "row card-not-selected center"
              }
              onClick={() => {
                setActualCard(4)
                setActiveKey(
                  intl.formatMessage({
                    id: "card-4-faq-1-title",
                  })
                )
              }}
              bordered
              hoverable
            >
              <IconFAQActiveType />
              <div className="col">
                <h3 style={{ fontSize: 18, fontFamily: "Cairo-Bold" }}>
                  {stringToHTML(
                    intl.formatMessage({ id: "faqs-card-4-title" })
                  )}
                </h3>
              </div>
            </Card>
          </div>
        </div>

        {actualCard === 1 && (
          <>
            <div className="faqs-title-intro">
              <p>
                {stringToHTML(intl.formatMessage({ id: "faqs-title-intro-1" }))}
              </p>
              <p>
                {stringToHTML(
                  intl.formatMessage({ id: "faqs-title-description-1" })
                )}
              </p>
            </div>
            <div className="faqs-content">
              <Collapse
                defaultActiveKey={intl.formatMessage({
                  id: "card-1-faq-1-title",
                })}
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <MinusCircleFilled
                      style={{
                        color: darkGrayColor,
                        fontSize: "30px",
                        opacity: "0.5",
                      }}
                    />
                  ) : (
                    <PlusCircleFilled
                      style={{ color: darkGrayColor, fontSize: "30px" }}
                    />
                  )
                }
                accordion
                ghost
                bordered={false}
                expandIconPosition="left"
                onChange={key => {
                  setActiveKey(key)
                }}
              >
                <Collapse.Panel
                  key={intl.formatMessage({ id: "card-1-faq-1-title" })}
                  header={
                    <div
                      className={
                        activeKey ===
                        intl.formatMessage({ id: "card-1-faq-1-title" })
                          ? "panel-header selected"
                          : "panel-header"
                      }
                    >
                      {stringToHTML(
                        intl.formatMessage({ id: "card-1-faq-1-title" })
                      )}
                    </div>
                  }
                >
                  <p
                    className="panel-message"
                    dangerouslySetInnerHTML={{
                      __html: stringToHTML(
                        intl.formatMessage({ id: "card-1-faq-1-description" })
                      ),
                    }}
                  />
                </Collapse.Panel>
                <Collapse.Panel
                  key={intl.formatMessage({ id: "card-1-faq-2-title" })}
                  header={
                    <div
                      className={
                        activeKey ===
                        intl.formatMessage({ id: "card-1-faq-2-title" })
                          ? "panel-header selected"
                          : "panel-header"
                      }
                    >
                      {stringToHTML(
                        intl.formatMessage({ id: "card-1-faq-2-title" })
                      )}
                    </div>
                  }
                >
                  <p
                    className="panel-message"
                    dangerouslySetInnerHTML={{
                      __html: stringToHTML(
                        intl.formatMessage({ id: "card-1-faq-2-description" })
                      ),
                    }}
                  />
                </Collapse.Panel>
                <Collapse.Panel
                  key={intl.formatMessage({ id: "card-1-faq-3-title" })}
                  header={
                    <div
                      className={
                        activeKey ===
                        intl.formatMessage({ id: "card-1-faq-3-title" })
                          ? "panel-header selected"
                          : "panel-header"
                      }
                    >
                      {stringToHTML(
                        intl.formatMessage({ id: "card-1-faq-3-title" })
                      )}
                    </div>
                  }
                >
                  <p
                    className="panel-message"
                    dangerouslySetInnerHTML={{
                      __html: stringToHTML(
                        intl.formatMessage({ id: "card-1-faq-3-description" })
                      ),
                    }}
                  />
                </Collapse.Panel>
                <Collapse.Panel
                  key={intl.formatMessage({ id: "card-1-faq-4-title" })}
                  header={
                    <div
                      className={
                        activeKey ===
                        intl.formatMessage({ id: "card-1-faq-4-title" })
                          ? "panel-header selected"
                          : "panel-header"
                      }
                    >
                      {stringToHTML(
                        intl.formatMessage({ id: "card-1-faq-4-title" })
                      )}
                    </div>
                  }
                >
                  <p
                    className="panel-message"
                    dangerouslySetInnerHTML={{
                      __html: stringToHTML(
                        intl.formatMessage({ id: "card-1-faq-4-description" })
                      ),
                    }}
                  />
                </Collapse.Panel>
                <Collapse.Panel
                  key={intl.formatMessage({ id: "card-1-faq-5-title" })}
                  header={
                    <div
                      className={
                        activeKey ===
                        intl.formatMessage({ id: "card-1-faq-5-title" })
                          ? "panel-header selected"
                          : "panel-header"
                      }
                    >
                      {stringToHTML(
                        intl.formatMessage({ id: "card-1-faq-5-title" })
                      )}
                    </div>
                  }
                >
                  <p
                    className="panel-message"
                    dangerouslySetInnerHTML={{
                      __html: stringToHTML(
                        intl.formatMessage({ id: "card-1-faq-5-description" })
                      ),
                    }}
                  />
                </Collapse.Panel>
                <Collapse.Panel
                  key={intl.formatMessage({ id: "card-1-faq-6-title" })}
                  header={
                    <div
                      className={
                        activeKey ===
                        intl.formatMessage({ id: "card-1-faq-6-title" })
                          ? "panel-header selected"
                          : "panel-header"
                      }
                    >
                      {stringToHTML(
                        intl.formatMessage({ id: "card-1-faq-6-title" })
                      )}
                    </div>
                  }
                >
                  <p
                    className="panel-message"
                    dangerouslySetInnerHTML={{
                      __html: stringToHTML(
                        intl.formatMessage({ id: "card-1-faq-6-description" })
                      ),
                    }}
                  />
                </Collapse.Panel>
                <Collapse.Panel
                  key={intl.formatMessage({ id: "card-1-faq-7-title" })}
                  header={
                    <div
                      className={
                        activeKey ===
                        intl.formatMessage({ id: "card-1-faq-7-title" })
                          ? "panel-header selected"
                          : "panel-header"
                      }
                    >
                      {stringToHTML(
                        intl.formatMessage({ id: "card-1-faq-7-title" })
                      )}
                    </div>
                  }
                >
                  <p
                    className="panel-message"
                    dangerouslySetInnerHTML={{
                      __html: stringToHTML(
                        intl.formatMessage({ id: "card-1-faq-7-description" })
                      ),
                    }}
                  />
                </Collapse.Panel>
                <Collapse.Panel
                  key={intl.formatMessage({ id: "card-1-faq-8-title" })}
                  header={
                    <div
                      className={
                        activeKey ===
                        intl.formatMessage({ id: "card-1-faq-8-title" })
                          ? "panel-header selected"
                          : "panel-header"
                      }
                    >
                      {stringToHTML(
                        intl.formatMessage({ id: "card-1-faq-8-title" })
                      )}
                    </div>
                  }
                >
                  <p
                    className="panel-message"
                    dangerouslySetInnerHTML={{
                      __html: stringToHTML(
                        intl.formatMessage({ id: "card-1-faq-8-description" })
                      ),
                    }}
                  />
                </Collapse.Panel>
              </Collapse>
            </div>
          </>
        )}
        {actualCard === 2 && (
          <>
            <div className="faqs-title-intro">
              <p>
                {stringToHTML(intl.formatMessage({ id: "faqs-title-intro-2" }))}
              </p>
              <p>
                {stringToHTML(
                  intl.formatMessage({ id: "faqs-title-description-2" })
                )}
              </p>
            </div>
            <div className="faqs-content">
              <Collapse
                defaultActiveKey={intl.formatMessage({
                  id: "card-2-faq-1-title",
                })}
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <MinusCircleFilled
                      style={{
                        color: darkGrayColor,
                        fontSize: "30px",
                        opacity: "0.5",
                      }}
                    />
                  ) : (
                    <PlusCircleFilled
                      style={{ color: darkGrayColor, fontSize: "30px" }}
                    />
                  )
                }
                accordion
                ghost
                bordered={false}
                expandIconPosition="left"
                onChange={key => {
                  setActiveKey(key)
                }}
              >
                <Collapse.Panel
                  key={intl.formatMessage({ id: "card-2-faq-1-title" })}
                  header={
                    <div
                      className={
                        activeKey ===
                        intl.formatMessage({ id: "card-2-faq-1-title" })
                          ? "panel-header selected"
                          : "panel-header"
                      }
                    >
                      {stringToHTML(
                        intl.formatMessage({ id: "card-2-faq-1-title" })
                      )}
                    </div>
                  }
                >
                  <p
                    className="panel-message"
                    dangerouslySetInnerHTML={{
                      __html: stringToHTML(
                        intl.formatMessage({ id: "card-2-faq-1-description" })
                      ),
                    }}
                  />
                </Collapse.Panel>
                <Collapse.Panel
                  key={intl.formatMessage({ id: "card-2-faq-2-title" })}
                  header={
                    <div
                      className={
                        activeKey ===
                        intl.formatMessage({ id: "card-2-faq-2-title" })
                          ? "panel-header selected"
                          : "panel-header"
                      }
                    >
                      {stringToHTML(
                        intl.formatMessage({ id: "card-2-faq-2-title" })
                      )}
                    </div>
                  }
                >
                  <p className="panel-message">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: stringToHTML(
                          intl.formatMessage({ id: "card-2-faq-2-description" })
                        ),
                      }}
                    />
                    <Link to={lPhantPrivacyPolicyLink}>
                      {lPhantPrivacyPolicyLinkDisplay}
                    </Link>
                  </p>
                </Collapse.Panel>
                <Collapse.Panel
                  key={intl.formatMessage({ id: "card-2-faq-3-title" })}
                  header={
                    <div
                      className={
                        activeKey ===
                        intl.formatMessage({ id: "card-2-faq-3-title" })
                          ? "panel-header selected"
                          : "panel-header"
                      }
                    >
                      {stringToHTML(
                        intl.formatMessage({ id: "card-2-faq-3-title" })
                      )}
                    </div>
                  }
                >
                  <p
                    className="panel-message"
                    dangerouslySetInnerHTML={{
                      __html: stringToHTML(
                        intl.formatMessage({ id: "card-2-faq-3-description" })
                      ),
                    }}
                  />
                </Collapse.Panel>
              </Collapse>
            </div>
          </>
        )}
        {actualCard === 3 && (
          <>
            <div className="faqs-title-intro">
              <p>
                {stringToHTML(intl.formatMessage({ id: "faqs-title-intro-3" }))}
              </p>
              <p>
                {stringToHTML(
                  intl.formatMessage({ id: "faqs-title-description-3" })
                )}
              </p>
            </div>
            <div className="faqs-content">
              <Collapse
                defaultActiveKey={intl.formatMessage({
                  id: "card-3-faq-1-title",
                })}
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <MinusCircleFilled
                      style={{
                        color: darkGrayColor,
                        fontSize: "30px",
                        opacity: "0.5",
                      }}
                    />
                  ) : (
                    <PlusCircleFilled
                      style={{ color: darkGrayColor, fontSize: "30px" }}
                    />
                  )
                }
                accordion
                ghost
                bordered={false}
                expandIconPosition="left"
                onChange={key => {
                  setActiveKey(key)
                }}
              >
                <Collapse.Panel
                  key={intl.formatMessage({ id: "card-3-faq-1-title" })}
                  header={
                    <div
                      className={
                        activeKey ===
                        intl.formatMessage({ id: "card-3-faq-1-title" })
                          ? "panel-header selected"
                          : "panel-header"
                      }
                    >
                      {stringToHTML(
                        intl.formatMessage({ id: "card-3-faq-1-title" })
                      )}
                    </div>
                  }
                >
                  <p
                    className="panel-message"
                    dangerouslySetInnerHTML={{
                      __html: stringToHTML(
                        intl.formatMessage({ id: "card-3-faq-1-description" })
                      ),
                    }}
                  />
                </Collapse.Panel>
                <Collapse.Panel
                  key={intl.formatMessage({ id: "card-3-faq-2-title" })}
                  header={
                    <div
                      className={
                        activeKey ===
                        intl.formatMessage({ id: "card-3-faq-2-title" })
                          ? "panel-header selected"
                          : "panel-header"
                      }
                    >
                      {stringToHTML(
                        intl.formatMessage({ id: "card-3-faq-2-title" })
                      )}
                    </div>
                  }
                >
                  <p
                    className="panel-message"
                    dangerouslySetInnerHTML={{
                      __html: stringToHTML(
                        intl.formatMessage({ id: "card-3-faq-2-description" })
                      ),
                    }}
                  />
                </Collapse.Panel>
                <Collapse.Panel
                  key={intl.formatMessage({ id: "card-3-faq-3-title" })}
                  header={
                    <div
                      className={
                        activeKey ===
                        intl.formatMessage({ id: "card-3-faq-3-title" })
                          ? "panel-header selected"
                          : "panel-header"
                      }
                    >
                      {stringToHTML(
                        intl.formatMessage({ id: "card-3-faq-3-title" })
                      )}
                    </div>
                  }
                >
                  <p
                    className="panel-message"
                    dangerouslySetInnerHTML={{
                      __html: stringToHTML(
                        intl.formatMessage({ id: "card-3-faq-3-description" })
                      ),
                    }}
                  />
                </Collapse.Panel>
                <Collapse.Panel
                  key={intl.formatMessage({ id: "card-3-faq-4-title" })}
                  header={
                    <div
                      className={
                        activeKey ===
                        intl.formatMessage({ id: "card-3-faq-4-title" })
                          ? "panel-header selected"
                          : "panel-header"
                      }
                    >
                      {stringToHTML(
                        intl.formatMessage({ id: "card-3-faq-4-title" })
                      )}
                    </div>
                  }
                >
                  <p
                    className="panel-message"
                    dangerouslySetInnerHTML={{
                      __html: stringToHTML(
                        intl.formatMessage({ id: "card-3-faq-4-description" })
                      ),
                    }}
                  />
                </Collapse.Panel>
                <Collapse.Panel
                  key={intl.formatMessage({ id: "card-3-faq-5-title" })}
                  header={
                    <div
                      className={
                        activeKey ===
                        intl.formatMessage({ id: "card-3-faq-5-title" })
                          ? "panel-header selected"
                          : "panel-header"
                      }
                    >
                      {stringToHTML(
                        intl.formatMessage({ id: "card-3-faq-5-title" })
                      )}
                    </div>
                  }
                >
                  <p
                    className="panel-message"
                    dangerouslySetInnerHTML={{
                      __html: stringToHTML(
                        intl.formatMessage({ id: "card-3-faq-5-description" })
                      ),
                    }}
                  />
                </Collapse.Panel>
                <Collapse.Panel
                  key={intl.formatMessage({ id: "card-3-faq-6-title" })}
                  header={
                    <div
                      className={
                        activeKey ===
                        intl.formatMessage({ id: "card-3-faq-6-title" })
                          ? "panel-header selected"
                          : "panel-header"
                      }
                    >
                      {stringToHTML(
                        intl.formatMessage({ id: "card-3-faq-6-title" })
                      )}
                    </div>
                  }
                >
                  <p
                    className="panel-message"
                    dangerouslySetInnerHTML={{
                      __html: stringToHTML(
                        intl.formatMessage({ id: "card-3-faq-6-description" })
                      ),
                    }}
                  />
                </Collapse.Panel>
                <Collapse.Panel
                  key={intl.formatMessage({ id: "card-3-faq-7-title" })}
                  header={
                    <div
                      className={
                        activeKey ===
                        intl.formatMessage({ id: "card-3-faq-7-title" })
                          ? "panel-header selected"
                          : "panel-header"
                      }
                    >
                      {stringToHTML(
                        intl.formatMessage({ id: "card-3-faq-7-title" })
                      )}
                    </div>
                  }
                >
                  <p
                    className="panel-message"
                    dangerouslySetInnerHTML={{
                      __html: stringToHTML(
                        intl.formatMessage({ id: "card-3-faq-7-description" })
                      ),
                    }}
                  />
                </Collapse.Panel>
                <Collapse.Panel
                  key={intl.formatMessage({ id: "card-3-faq-8-title" })}
                  header={
                    <div
                      className={
                        activeKey ===
                        intl.formatMessage({ id: "card-3-faq-8-title" })
                          ? "panel-header selected"
                          : "panel-header"
                      }
                    >
                      {stringToHTML(
                        intl.formatMessage({ id: "card-3-faq-8-title" })
                      )}
                    </div>
                  }
                >
                  <p
                    className="panel-message"
                    dangerouslySetInnerHTML={{
                      __html: stringToHTML(
                        intl.formatMessage({ id: "card-3-faq-8-description" })
                      ),
                    }}
                  />
                </Collapse.Panel>
                <Collapse.Panel
                  key={intl.formatMessage({ id: "card-3-faq-9-title" })}
                  header={
                    <div
                      className={
                        activeKey ===
                        intl.formatMessage({ id: "card-3-faq-9-title" })
                          ? "panel-header selected"
                          : "panel-header"
                      }
                    >
                      {stringToHTML(
                        intl.formatMessage({ id: "card-3-faq-9-title" })
                      )}
                    </div>
                  }
                >
                  <p
                    className="panel-message"
                    dangerouslySetInnerHTML={{
                      __html: stringToHTML(
                        intl.formatMessage({ id: "card-3-faq-9-description" })
                      ),
                    }}
                  />
                </Collapse.Panel>
                <Collapse.Panel
                  key={intl.formatMessage({ id: "card-3-faq-10-title" })}
                  header={
                    <div
                      className={
                        activeKey ===
                        intl.formatMessage({ id: "card-3-faq-10-title" })
                          ? "panel-header selected"
                          : "panel-header"
                      }
                    >
                      {stringToHTML(
                        intl.formatMessage({ id: "card-3-faq-10-title" })
                      )}
                    </div>
                  }
                >
                  <p
                    className="panel-message"
                    dangerouslySetInnerHTML={{
                      __html: stringToHTML(
                        intl.formatMessage({ id: "card-3-faq-10-description" })
                      ),
                    }}
                  />
                </Collapse.Panel>
                <Collapse.Panel
                  key={intl.formatMessage({ id: "card-3-faq-11-title" })}
                  header={
                    <div
                      className={
                        activeKey ===
                        intl.formatMessage({ id: "card-3-faq-11-title" })
                          ? "panel-header selected"
                          : "panel-header"
                      }
                    >
                      {stringToHTML(
                        intl.formatMessage({ id: "card-3-faq-11-title" })
                      )}
                    </div>
                  }
                >
                  <p
                    className="panel-message"
                    dangerouslySetInnerHTML={{
                      __html: stringToHTML(
                        intl.formatMessage({ id: "card-3-faq-11-description" })
                      ),
                    }}
                  />
                </Collapse.Panel>
                <Collapse.Panel
                  key={intl.formatMessage({ id: "card-3-faq-12-title" })}
                  header={
                    <div
                      className={
                        activeKey ===
                        intl.formatMessage({ id: "card-3-faq-12-title" })
                          ? "panel-header selected"
                          : "panel-header"
                      }
                    >
                      {stringToHTML(
                        intl.formatMessage({ id: "card-3-faq-12-title" })
                      )}
                    </div>
                  }
                >
                  <p
                    className="panel-message"
                    dangerouslySetInnerHTML={{
                      __html: stringToHTML(
                        intl.formatMessage({ id: "card-3-faq-12-description" })
                      ),
                    }}
                  />
                </Collapse.Panel>
              </Collapse>
            </div>
          </>
        )}
        {actualCard === 4 && (
          <>
            <div className="faqs-title-intro">
              <p>
                {stringToHTML(intl.formatMessage({ id: "faqs-title-intro-4" }))}
              </p>
              <p>
                {stringToHTML(
                  intl.formatMessage({ id: "faqs-title-description-4" })
                )}
              </p>
            </div>
            <div className="faqs-content">
              <Collapse
                defaultActiveKey={intl.formatMessage({
                  id: "card-4-faq-1-title",
                })}
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <MinusCircleFilled
                      style={{
                        color: darkGrayColor,
                        fontSize: "30px",
                        opacity: "0.5",
                      }}
                    />
                  ) : (
                    <PlusCircleFilled
                      style={{ color: darkGrayColor, fontSize: "30px" }}
                    />
                  )
                }
                accordion
                ghost
                bordered={false}
                expandIconPosition="left"
                onChange={key => {
                  setActiveKey(key)
                }}
              >
                <Collapse.Panel
                  key={intl.formatMessage({ id: "card-4-faq-1-title" })}
                  header={
                    <div
                      className={
                        activeKey ===
                        intl.formatMessage({ id: "card-4-faq-1-title" })
                          ? "panel-header selected"
                          : "panel-header"
                      }
                    >
                      {stringToHTML(
                        intl.formatMessage({ id: "card-4-faq-1-title" })
                      )}
                    </div>
                  }
                >
                  <p
                    className="panel-message"
                    dangerouslySetInnerHTML={{
                      __html: stringToHTML(
                        intl.formatMessage({ id: "card-4-faq-1-description" })
                      ),
                    }}
                  />
                </Collapse.Panel>
                <Collapse.Panel
                  key={intl.formatMessage({ id: "card-4-faq-2-title" })}
                  header={
                    <div
                      className={
                        activeKey ===
                        intl.formatMessage({ id: "card-4-faq-2-title" })
                          ? "panel-header selected"
                          : "panel-header"
                      }
                    >
                      {stringToHTML(
                        intl.formatMessage({ id: "card-4-faq-2-title" })
                      )}
                    </div>
                  }
                >
                  <p
                    className="panel-message"
                    dangerouslySetInnerHTML={{
                      __html: stringToHTML(
                        intl.formatMessage({ id: "card-4-faq-2-description" })
                      ),
                    }}
                  />
                </Collapse.Panel>
                <Collapse.Panel
                  key={intl.formatMessage({ id: "card-4-faq-3-title" })}
                  header={
                    <div
                      className={
                        activeKey ===
                        intl.formatMessage({ id: "card-4-faq-3-title" })
                          ? "panel-header selected"
                          : "panel-header"
                      }
                    >
                      {stringToHTML(
                        intl.formatMessage({ id: "card-4-faq-3-title" })
                      )}
                    </div>
                  }
                >
                  <p
                    className="panel-message"
                    dangerouslySetInnerHTML={{
                      __html: stringToHTML(
                        intl.formatMessage({ id: "card-4-faq-3-description" })
                      ),
                    }}
                  />
                </Collapse.Panel>
                <Collapse.Panel
                  key={intl.formatMessage({ id: "card-4-faq-4-title" })}
                  header={
                    <div
                      className={
                        activeKey ===
                        intl.formatMessage({ id: "card-4-faq-4-title" })
                          ? "panel-header selected"
                          : "panel-header"
                      }
                    >
                      {stringToHTML(
                        intl.formatMessage({ id: "card-4-faq-4-title" })
                      )}
                    </div>
                  }
                >
                  <p
                    className="panel-message"
                    dangerouslySetInnerHTML={{
                      __html: stringToHTML(
                        intl.formatMessage({ id: "card-4-faq-4-description" })
                      ),
                    }}
                  />
                </Collapse.Panel>
                <Collapse.Panel
                  key={intl.formatMessage({ id: "card-4-faq-5-title" })}
                  header={
                    <div
                      className={
                        activeKey ===
                        intl.formatMessage({ id: "card-4-faq-5-title" })
                          ? "panel-header selected"
                          : "panel-header"
                      }
                    >
                      {stringToHTML(
                        intl.formatMessage({ id: "card-4-faq-5-title" })
                      )}
                    </div>
                  }
                >
                  <p
                    className="panel-message"
                    dangerouslySetInnerHTML={{
                      __html: stringToHTML(
                        intl.formatMessage({ id: "card-4-faq-5-description" })
                      ),
                    }}
                  />
                </Collapse.Panel>
                <Collapse.Panel
                  key={intl.formatMessage({ id: "card-4-faq-6-title" })}
                  header={
                    <div
                      className={
                        activeKey ===
                        intl.formatMessage({ id: "card-4-faq-6-title" })
                          ? "panel-header selected"
                          : "panel-header"
                      }
                    >
                      {stringToHTML(
                        intl.formatMessage({ id: "card-4-faq-6-title" })
                      )}
                    </div>
                  }
                >
                  <p
                    className="panel-message"
                    dangerouslySetInnerHTML={{
                      __html: stringToHTML(
                        intl.formatMessage({ id: "card-4-faq-6-description" })
                      ),
                    }}
                  />
                </Collapse.Panel>
                <Collapse.Panel
                  key={intl.formatMessage({ id: "card-4-faq-7-title" })}
                  header={
                    <div
                      className={
                        activeKey ===
                        intl.formatMessage({ id: "card-4-faq-7-title" })
                          ? "panel-header selected"
                          : "panel-header"
                      }
                    >
                      {stringToHTML(
                        intl.formatMessage({ id: "card-4-faq-7-title" })
                      )}
                    </div>
                  }
                >
                  <p
                    className="panel-message"
                    dangerouslySetInnerHTML={{
                      __html: stringToHTML(
                        intl.formatMessage({ id: "card-4-faq-7-description" })
                      ),
                    }}
                  />
                </Collapse.Panel>
              </Collapse>
            </div>
          </>
        )}
      </div>
    </Layout>
  )
}

export default injectIntl(FAQs)
